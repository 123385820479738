export const ar = {
  form: {
    full_name: {
      label: 'أدخل اسمك الكامل',
      required: 'اسم الخاص بك مطلوب',
    },
    bio: {
      label: 'السيرة الذاتية',
      required: 'سيرتك الذاتية مطلوبة',
    },
    address: {
      label: 'موقعك',
      required: 'موقعك مطلوب',
    },
    profile_picture_url: {
      label: 'الصوره الشخصيه',
      required: 'صورة الملف الشخصي مطلوب',
    },
  },
  button: {
    sort_by: 'ترتيب حسب',
    delete_picture: 'حذف الصورة',
    save_setting: 'حفظ الإعداد',
  },
};
