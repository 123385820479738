import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { ROOTS_TYPE } from 'src/routes/paths';

import { hideScroll } from 'src/theme/css';
import { UserRoleType } from 'src/auth/types';
import { useAuthContext } from 'src/auth/hooks';

import LogoOnly from 'src/components/logo/logo-only';
import { NavSectionMini } from 'src/components/nav-section';

import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import NavToggleButton from '../common/nav-toggle-button';

// ----------------------------------------------------------------------

export default function NavMini() {
  const { user } = useAuthContext();

  const activeRole =
    user && user.activeRole ? user.activeRole.toLowerCase() : UserRoleType.CLUSTER_ADMIN;

  let role: ROOTS_TYPE = activeRole;

  if (role.toLocaleLowerCase() === UserRoleType.CLUSTER_ADMIN) {
    role = 'ADMIN';
  }

  const navData = useNavData(role.toLocaleUpperCase() as ROOTS_TYPE);

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          borderRight: (theme) => `solid 1px ${theme.palette.divider}`,
          ...hideScroll.x,
        }}
      >
        <LogoOnly sx={{ mx: 'auto', my: 3 }} />

        <NavSectionMini
          data={navData}
          slotProps={{
            currentRole: activeRole,
          }}
        />
      </Stack>
    </Box>
  );
}
