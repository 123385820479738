import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router';

import { identifyRoles } from 'src/utils/generate-paths';

import DashboardLayout from 'src/layouts/dashboard';
import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';

import { LoadingScreen } from 'src/components/loading-screen';

import { PATHS_DASHBOARD } from '../paths';

// ----------------------------------------------------------------------

// OVERVIEW
const StatisticsPage = lazy(() => import('src/pages/operator/statistics'));

// PROJECTS
const ProjectsPage = lazy(() => import('src/pages/project/all'));
const AddProjectPage = lazy(() => import('src/pages/project/add'));
const DetailsProjectPage = lazy(() => import('src/pages/project/details'));
const CampaignListOnProjectPage = lazy(() => import('src/pages/project/campaign-list'));
const EditRequestProjectPage = lazy(() => import('src/pages/project/edit-request'));
const ItemListOnProjectPage = lazy(() => import('src/pages/project/item-list'));
const ProjectTransactionDonorsPage = lazy(
  () => import('src/pages/project/transaction-donors-list')
);

// CAMPAIGNS
const AllCampaignPage = lazy(() => import('src/pages/campaign/all'));
const AddCampaignPage = lazy(() => import('src/pages/campaign/add'));
const DetailsCampaignPage = lazy(() => import('src/pages/campaign/details'));
const EditRequestCampaignPage = lazy(() => import('src/pages/campaign/edit-request'));
const CampaignsTransactionsPage = lazy(() => import('src/pages/campaign/transaction-list'));
const CampaignsTransactionDonorsPage = lazy(
  () => import('src/pages/campaign/transaction-donors-list')
);
const CampaignVendorRequestsPage = lazy(() => import('src/pages/campaign/vendor-requests'));
const CampaignDetailVendorRequestPage = lazy(
  () => import('src/pages/campaign/detail-vendor-request')
);

const CampaignVendorRequestChat = lazy(() => import('src/pages/campaign/vendor-request-chat'));
const CampaignVendorsPage = lazy(() => import('src/pages/campaign/vendor-list'));
const CampaignVendorDetailsPage = lazy(() => import('src/pages/campaign/vendor-details'));
const SendBackRequestCampaignPage = lazy(() => import('src/pages/campaign/send-back-requests'));
const DetailSendBackRequestCampaignPage = lazy(
  () => import('src/pages/campaign/detail-send-back-request')
);

// MARKETPLACE item
const AllMarketplaceItemPage = lazy(() => import('src/pages/marketplace-item/all'));
const AddMarketplaceItemPage = lazy(() => import('src/pages/marketplace-item/add'));
const DetailsMarketplaceItemPage = lazy(() => import('src/pages/marketplace-item/details'));
const EditMarketplaceItemPage = lazy(() => import('src/pages/marketplace-item/edit'));
const EditRequestMarketplaceItemPage = lazy(
  () => import('src/pages/marketplace-item/edit-request')
);
const MarketplaceItemsTransactionDonorsPage = lazy(
  () => import('src/pages/marketplace-item/transaction-donors-list')
);

// blogs

const AllBlogsPage = lazy(() => import('src/pages/blog/all'));
const AddBlogPage = lazy(() => import('src/pages/blog/create'));
const EditBlogPage = lazy(() => import('src/pages/blog/edit'));

// Vendors
const VendorMilestonProgressPage = lazy(() => import('src/pages/vendor/milestone-progress'));
const VendorMilestoneInvoicesPage = lazy(() => import('src/pages/vendor/milestone-invoices'));
const COPayToVendorPage = lazy(() => import('src/pages/campaign/operator-pay-to-vendor'));

// Settings
const SettingsAccountInfoPage = lazy(() => import('src/pages/settings/account-info'));

// Tickets
const AllTicketsPage = lazy(() => import('src/pages/ticket/all'));
const TicketDetailPage = lazy(() => import('src/pages/ticket/detail'));

// Volunteer
const AllVolunteerPage = lazy(() => import('src/pages/volunteer/all'));
const DetailVolunteerRequestPage = lazy(() => import('src/pages/volunteer/detail-request'));
const VolunteerAddNewTaskPage = lazy(() => import('src/pages/volunteer/add-new-task'));
const VolunteerCampaignTaskListPage = lazy(() => import('src/pages/volunteer/campaign-tasks'));
const VolunteerCampaignTaskDetailPage = lazy(
  () => import('src/pages/volunteer/detail-campaign-task')
);
const VolunteerCampaignTaskEditPage = lazy(() => import('src/pages/volunteer/edit-campaign-task'));
const VolunteerApplyTaskListPage = lazy(() => import('src/pages/volunteer/apply-tasks'));

// ----------------------------------------------------------------------

const identifiedPaths = identifyRoles('operator');

const identifiedPathsV2 = PATHS_DASHBOARD('OPERATOR');

// ----------------------------------------------------------------------

export const operatorRoutes = {
  path: 'operator',
  element: (
    <AuthGuard>
      <RoleBasedGuard hasContent roles={['OPERATOR']} sx={{ py: 10 }}>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </RoleBasedGuard>
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to="/operator/dashboard/statistics" replace />, index: true },
    {
      path: 'dashboard',
      children: [
        { element: <Navigate to="/operator/dashboard/statistics" replace />, index: true },
        {
          path: 'statistics',
          element: <StatisticsPage title="menu.dashboard" activeRole="roles.OPERATOR" />,
        },
      ],
    },
    {
      path: 'project',
      children: [
        { element: <Navigate to="/operator/project/all" replace />, index: true },
        {
          path: 'detail',
          children: [
            { element: <Navigate to="/operator/project/all" replace />, index: true },
            {
              path: ':id',
              children: [
                {
                  path: '',
                  element: <DetailsProjectPage paths={identifiedPaths!} />,
                },
                {
                  path: 'campaigns',
                  element: (
                    <CampaignListOnProjectPage
                      title="menu.campaign.title"
                      activeRole="roles.OPERATOR"
                      paths={identifiedPathsV2}
                    />
                  ),
                },
                {
                  path: 'items',
                  element: (
                    <ItemListOnProjectPage
                      title="menu.marketplace.title"
                      activeRole="roles.OPERATOR"
                      paths={identifiedPaths!}
                    />
                  ),
                },
                {
                  path: 'donors',
                  element: (
                    <ProjectTransactionDonorsPage
                      title="menu.campaign.donor_list_project"
                      activeRole="roles.OPERATOR"
                      paths={identifiedPaths!}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'edit-request',
          children: [
            { element: <Navigate to="/operator/project/all" replace />, index: true },
            {
              path: ':id',
              element: (
                <EditRequestProjectPage
                  title="menu.project.edit_request"
                  activeRole="roles.OPERATOR"
                  paths={identifiedPaths!}
                />
              ),
            },
          ],
        },
        {
          path: 'all',
          element: (
            <ProjectsPage
              title="menu.project.my_projects"
              activeRole="roles.OPERATOR"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: 'add',
          element: (
            <AddProjectPage
              title="menu.project.add_project"
              activeRole="roles.OPERATOR"
              paths={identifiedPaths!}
            />
          ),
        },
      ],
    },
    {
      path: 'campaign',
      children: [
        { element: <Navigate to="/operator/campaign/all" replace />, index: true },
        {
          path: 'detail',
          children: [
            { element: <Navigate to="/operator/campaign/all" replace />, index: true },
            {
              path: ':id',
              children: [
                {
                  path: '',
                  element: (
                    <DetailsCampaignPage
                      title="menu.campaign.details"
                      activeRole="roles.OPERATOR"
                      paths={identifiedPathsV2}
                    />
                  ),
                },
                {
                  path: 'payment-history',
                  element: (
                    <CampaignsTransactionsPage
                      title="menu.campaign.payment_history"
                      activeRole="roles.OPERATOR"
                      paths={identifiedPaths!}
                    />
                  ),
                },
                {
                  path: 'donors',
                  element: (
                    <CampaignsTransactionDonorsPage
                      title="menu.campaign.donor_list_campaign"
                      activeRole="roles.OPERATOR"
                      paths={identifiedPaths!}
                    />
                  ),
                },
                {
                  path: 'vendors',
                  children: [
                    {
                      path: '',
                      element: (
                        <CampaignVendorsPage
                          title="menu.vendors.title"
                          activeRole="roles.OPERATOR"
                          paths={identifiedPathsV2}
                        />
                      ),
                    },
                    {
                      path: ':vendor_id',
                      children: [
                        {
                          path: '',
                          element: (
                            <CampaignVendorDetailsPage
                              title="menu.vendors.detail_vendor"
                              activeRole="roles.OPERATOR"
                              paths={identifiedPathsV2}
                            />
                          ),
                        },
                        {
                          path: 'progresses',
                          element: (
                            <VendorMilestonProgressPage
                              title="menu.vendors.milestone_progress"
                              activeRole="roles.OPERATOR"
                              paths={identifiedPathsV2}
                            />
                          ),
                        },
                        {
                          path: 'invoices',
                          element: (
                            <VendorMilestoneInvoicesPage
                              title="menu.vendors.milestone_invoices"
                              activeRole="roles.OPERATOR"
                              paths={identifiedPathsV2}
                            />
                          ),
                        },
                        {
                          path: 'pay-to-vendor',
                          element: (
                            <COPayToVendorPage
                              title="section.admin.dashboard.label.pay_to_vendor"
                              activeRole="roles.OPERATOR"
                              paths={identifiedPathsV2}
                            />
                          ),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'edit-request',
          children: [
            { element: <Navigate to="/operator/campaign/all" replace />, index: true },
            {
              path: ':id',
              element: (
                <EditRequestCampaignPage
                  title="menu.campaign.edit_request"
                  activeRole="roles.OPERATOR"
                  paths={identifiedPaths!}
                />
              ),
            },
          ],
        },
        {
          path: 'all',
          element: (
            <AllCampaignPage
              title="menu.campaign.all_campaign"
              activeRole="roles.OPERATOR"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: 'add',
          element: (
            <AddCampaignPage
              title="menu.campaign.add_campaign"
              activeRole="roles.OPERATOR"
              paths={identifiedPaths!}
            />
          ),
        },
        {
          path: 'vendor-requests',
          children: [
            {
              path: '',
              element: (
                <CampaignVendorRequestsPage
                  title="menu.campaign.vendor_requests"
                  activeRole="roles.OPERATOR"
                  paths={identifiedPathsV2}
                />
              ),
            },
            {
              path: ':id',
              element: (
                <CampaignDetailVendorRequestPage
                  title="menu.campaign.detail_vendor_request"
                  activeRole="roles.OPERATOR"
                  paths={identifiedPathsV2}
                />
              ),
            },
            {
              path: 'chat/:id',
              element: (
                <CampaignVendorRequestChat
                  title="menu.campaign.detail_vendor_request"
                  activeRole="roles.OPERATOR"
                  paths={identifiedPathsV2}
                />
              ),
            },
          ],
        },
        {
          path: 'send-back-requests',
          children: [
            {
              path: '',
              element: (
                <SendBackRequestCampaignPage
                  title="menu.campaign.send_back_requests"
                  activeRole="roles.OPERATOR"
                  paths={identifiedPathsV2}
                />
              ),
            },
            {
              path: ':campaign_edit_request_id',
              element: (
                <DetailSendBackRequestCampaignPage
                  title="menu.campaign.detail_send_back_requests"
                  activeRole="roles.OPERATOR"
                  paths={identifiedPathsV2}
                />
              ),
            },
          ],
        },
      ],
    },
    {
      path: 'blog',
      children: [
        { element: <Navigate to="/admin/blog/all" replace />, index: true },
        {
          path: 'all',
          element: (
            <AllBlogsPage
              title="section.blog.title.all_blogs"
              activeRole="roles.OPERATOR"
              paths={identifiedPathsV2!}
            />
          ),
        },
        {
          path: 'add',
          element: (
            <AddBlogPage
              title="section.blog.title.new_blog"
              activeRole="roles.OPERATOR"
              paths={identifiedPathsV2!}
            />
          ),
        },
        {
          path: 'edit',
          children: [
            { element: <Navigate to="/admin/blog/all" replace />, index: true },
            {
              path: ':id',
              element: (
                <EditBlogPage
                  title="section.blog.title.edit"
                  activeRole="roles.OPERATOR"
                  paths={identifiedPathsV2!}
                />
              ),
            },
          ],
        },
      ],
    },
    {
      path: 'marketplace',
      children: [
        { element: <Navigate to="/operator/marketplace/all" replace />, index: true },
        {
          path: 'all',
          element: (
            <AllMarketplaceItemPage
              title="section.marketplace.title.project_marketplace"
              activeRole="roles.OPERATOR"
              paths={identifiedPaths!}
            />
          ),
        },
        {
          path: 'add',
          element: (
            <AddMarketplaceItemPage
              title="section.marketplace.button.add_new_item"
              activeRole="roles.OPERATOR"
              paths={identifiedPaths!}
            />
          ),
        },
        {
          path: 'detail',
          children: [
            { element: <Navigate to="/operator/marketplace/all" replace />, index: true },
            {
              path: ':id',
              children: [
                {
                  path: '',
                  element: (
                    <DetailsMarketplaceItemPage
                      title="menu.marketplace.details"
                      activeRole="roles.OPERATOR"
                      paths={identifiedPaths!}
                    />
                  ),
                },
                {
                  path: 'donors',
                  element: (
                    <MarketplaceItemsTransactionDonorsPage
                      title="menu.marketplace.donor_list_item"
                      activeRole="roles.OPERATOR"
                      paths={identifiedPaths!}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'edit',
          children: [
            { element: <Navigate to="/operator/marketplace/all" replace />, index: true },
            {
              path: ':id',
              element: (
                <EditMarketplaceItemPage
                  title="menu.marketplace.edit_item"
                  activeRole="roles.OPERATOR"
                  paths={identifiedPaths!}
                />
              ),
            },
          ],
        },
        {
          path: 'edit-request',
          children: [
            { element: <Navigate to="/operator/marketplace/all" replace />, index: true },
            {
              path: ':id',
              element: (
                <EditRequestMarketplaceItemPage
                  title="menu.marketplace.edit_request_item"
                  activeRole="roles.OPERATOR"
                  paths={identifiedPaths!}
                />
              ),
            },
          ],
        },
      ],
    },
    {
      path: 'settings',
      children: [
        { element: <Navigate to="/operator/settings/account-info" replace />, index: true },
        {
          path: 'account-info',
          element: (
            <SettingsAccountInfoPage
              title="menu.settings.account_info"
              activeRole="roles.OPERATOR"
              paths={identifiedPathsV2}
            />
          ),
        },
      ],
    },
    {
      path: 'tickets',
      children: [
        { element: <Navigate to="/operator/tickets/all" replace />, index: true },
        {
          path: 'all',
          element: (
            <AllTicketsPage
              title="menu.tickets.all_ticket"
              activeRole="roles.OPERATOR"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: 'detail',
          children: [
            { element: <Navigate to="/operator/tickets/all" replace />, index: true },
            {
              path: ':id',
              element: (
                <TicketDetailPage
                  title="menu.tickets.detail_ticket"
                  activeRole="roles.OPERATOR"
                  paths={identifiedPathsV2}
                />
              ),
            },
          ],
        },
      ],
    },
    {
      path: 'volunteer',
      children: [
        { element: <Navigate to="/operator/volunteer/all" replace />, index: true },
        {
          path: 'all',
          element: (
            <AllVolunteerPage
              title="section.volunteers.caption.all_volunteer"
              activeRole="roles.OPERATOR"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: 'detail',
          children: [
            { element: <Navigate to="/operator/volunteer/all" replace />, index: true },
            {
              path: ':volunteer_request_id',
              children: [
                {
                  path: '',
                  element: (
                    <DetailVolunteerRequestPage
                      title="section.volunteers.caption.detail_volunteer"
                      activeRole="roles.OPERATOR"
                      paths={identifiedPathsV2}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'add-new-task',
          element: (
            <VolunteerAddNewTaskPage
              title="section.volunteers.button.add_new_task"
              activeRole="roles.OPERATOR"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: 'campaign-tasks',
          children: [
            {
              element: <Navigate to="/operator/volunteer/campaign-tasks/all" replace />,
              index: true,
            },
            {
              path: 'all',
              element: (
                <VolunteerCampaignTaskListPage
                  title="section.volunteers.caption.campaign_task_for_volunteer"
                  activeRole="roles.OPERATOR"
                  paths={identifiedPathsV2}
                />
              ),
            },
            {
              path: 'detail',
              children: [
                {
                  element: <Navigate to="/operator/volunteer/campaign-tasks/all" />,
                  index: true,
                },
                {
                  path: ':campaign_task_id',
                  children: [
                    {
                      path: '',
                      element: (
                        <VolunteerCampaignTaskDetailPage
                          paths={identifiedPathsV2}
                          activeRole="roles.OPERATOR"
                          title="section.volunteers.caption.detail_campaign_task"
                        />
                      ),
                    },
                    {
                      path: 'edit',
                      element: (
                        <VolunteerCampaignTaskEditPage
                          paths={identifiedPathsV2}
                          activeRole="roles.OPERATOR"
                          title="section.volunteers.caption.edit_campaign_task"
                        />
                      ),
                    },
                    {
                      path: 'pending-volunteers',
                      element: (
                        <VolunteerApplyTaskListPage
                          paths={identifiedPathsV2}
                          type="pending"
                          activeRole="roles.OPERATOR"
                          title="section.admin.dashboard.label.total_pending_volunteers"
                        />
                      ),
                    },
                    {
                      path: 'assigned-volunteers',
                      element: (
                        <VolunteerApplyTaskListPage
                          type="approved"
                          paths={identifiedPathsV2}
                          activeRole="roles.OPERATOR"
                          title="section.admin.dashboard.label.total_assigned_volunteers"
                        />
                      ),
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
