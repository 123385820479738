import { memo } from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';

import { ROOTS_TYPE } from 'src/routes/paths';

import { bgBlur } from 'src/theme/css';
import { UserRoleType } from 'src/auth/types';
import { useAuthContext } from 'src/auth/hooks';

import Scrollbar from 'src/components/scrollbar';
import { NavSectionHorizontal } from 'src/components/nav-section';

import { HEADER } from '../config-layout';
import { useNavData } from './config-navigation';
import HeaderShadow from '../common/header-shadow';

// ----------------------------------------------------------------------

function NavHorizontal() {
  const theme = useTheme();

  const { user } = useAuthContext();

  const activeRole =
    user && user.activeRole ? user.activeRole.toLowerCase() : UserRoleType.CLUSTER_ADMIN;

  let role: ROOTS_TYPE = activeRole;

  if (role.toLocaleLowerCase() === UserRoleType.CLUSTER_ADMIN) {
    role = 'ADMIN';
  }

  const navData = useNavData(role.toLocaleUpperCase() as ROOTS_TYPE);

  return (
    <AppBar
      component="div"
      sx={{
        top: HEADER.H_DESKTOP_OFFSET,
      }}
    >
      <Toolbar
        sx={{
          ...bgBlur({
            color: theme.palette.background.default,
          }),
        }}
      >
        <Scrollbar
          sx={{
            '& .simplebar-content': {
              display: 'flex',
            },
          }}
        >
          <NavSectionHorizontal
            data={navData}
            slotProps={{
              currentRole: activeRole,
            }}
            sx={{
              ...theme.mixins.toolbar,
            }}
          />
        </Scrollbar>
      </Toolbar>

      <HeaderShadow />
    </AppBar>
  );
}

export default memo(NavHorizontal);
