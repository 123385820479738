export const ar = {
  caption: {
    regions: 'المناطق',
    region_details: 'تفاصيل المنطقة',
    governorates: 'الحكومات',
    governorate_details: 'تحافظ على التفاصيل',
    select_region: 'حدد المنطقة',
    add: 'يضيف',
    edit: 'يحرر',
    delete: 'يمسح',
    edit_governorate: 'تحرير المحافظة',
    add_new_governorate: 'أضف المحافظ',
    add_new_region: 'أضف المنطقة',
    edit_region: 'تحرير المنطقة',
  },
  form: {
    region_name: {
      label: 'اسم المنطقة',
      required: 'اسم المنطقة مطلوب',
    },
    governorate_name: {
      label: 'اسم المحافظ',
      required: 'اسم المحافظ مطلوب',
    },
    latitude: {
      label: 'خط العرض',
      placeholder: 'على سبيل المثال: 23.885942',
      required: 'خط العرض مطلوب',
      max_float: 'يجب أن يكون المبلغ رقمًا عشريًا بحد أقصى سبعة أرقام بعد الفاصلة',
      min: 'يجب أن يكون خط العرض -90 على الأقل',
      max: 'يجب أن يكون خط العرض 90 على الأقل',
      type_error: 'يجب أن يكون خط العرض رقمًا صالحًا',
    },
    longitude: {
      label: 'خط الطول',
      placeholder: 'على سبيل المثال: 45.0791621',
      required: 'خط الطول مطلوب',
      max_float: 'يجب أن يكون المبلغ رقمًا عشريًا بحد أقصى سبعة أرقام بعد الفاصلة',
      min: 'يجب أن يكون خط الطول -180 على الأقل',
      max: 'يجب أن يكون خط الطول 180 على الأقل',
      type_error: 'يجب أن يكون خط الطول رقمًا صالحًا',
    },
  },
};
