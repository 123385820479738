export const en = {
  title: {
    blog: 'Blog',
    all_blogs: 'All blogs',
    new_blog: 'New blog',
    create: 'Create new blog',
    edit: 'Edit blog',
    something_awesome: 'Write something awesome',
    drop_files: 'Drop files here or click to upload',
  },
  headers: {
    title: 'Title',
    date_of_write: 'Date of write',
    view: 'View',
    category: 'Category',
  },
  button: {
    view: 'View',
    edit_blog: 'Edit blog',
    delete_blog: 'Delete blog',
    add_blog: 'Add blog',
  },
  form: {
    content: {
      label: 'Content',
      required: 'Content is required',
    },
    name: {
      label: `Blog's Name`,
      required: `Blog's Name is required`,
    },
    description: {
      label: 'Description',
      required: 'Description is required',
    },
    coverUrl: {
      label: 'Cover image',
      required: 'Cover image url is required',
    },
    category: {
      label: 'Category',
      required: 'Category is required',
    },
    project: {
      label: 'Project',
      required: 'Project is required',
    },
  },
};
