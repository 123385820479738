export const ar = {
  button: {
    add_operator: 'إضافة المشغل',
    view: 'منظر',
    edit_operator: 'تحرير المشغل',
    delete_operator: 'حذف المشغل',
    approve: 'يعتمد',
    reject: 'رفض المشغل',
    upload_new_invoice: 'تحميل فاتورة جديدة',
    view_public_profile: 'عرض الملف الشخصي العام',
    back: 'خلف',
    public_page: 'الصفحة العامة للمشغل',
    profile_requests: 'طلبات الملف الشخصي',
  },
  form: {
    name: {
      label: 'اسم المشغل',
      required: 'اسم المشغل مطلوب',
    },
    level_of_access: {
      label: 'مستوى الوصول',
      required: 'مستوى الوصول مطلوب',
    },
    profile_picture: {
      label: 'تحميل صورة الملف الشخصي',
      required: 'صورة الملف الشخصي مطلوبة.',
    },
    country: {
      label: 'البلد',
      required: 'البلد مطلوب',
    },
    vendor_name: {
      label: 'إلى البائع',
      required: 'مطلوب اسم البائع',
    },
    selected_invoice: {
      label: 'حدد فاتورة',
      required: 'تحتاج إلى تحديد على الفاتورة',
    },
    reason_to_pay: {
      label: 'سبب للدفع',
      required: 'أنت بحاجة إلى سبب لدفع هذا البائع',
    },
  },
  headers: {
    date_added: 'تاريخ الاضافة',
    project: 'المشروع',
  },
  titles: {
    project_land: 'معرض المشاريع',
    donation_chart:'مخطط التبرع',
  },
};
