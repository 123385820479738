import { PATHS_ADMIN, PATHS_VENDOR, PATHS_OPERATOR, PATHS_EVALUATOR } from 'src/routes/paths';

import { UserRoleType } from 'src/auth/types';

export type PathsComponent =
  | typeof PATHS_ADMIN
  | typeof PATHS_OPERATOR
  | typeof PATHS_EVALUATOR
  | typeof PATHS_VENDOR;

type Paths<T extends PathsComponent> = T;

export const identifyRoles = (role: string): Paths<PathsComponent> | undefined => {
  let PATH: Paths<PathsComponent> | undefined;
  const active_role = role.toLowerCase();

  switch (active_role) {
    case UserRoleType.CLUSTER_ADMIN:
      PATH = PATHS_ADMIN;
      break;

    case UserRoleType.OPERATOR:
      PATH = PATHS_OPERATOR;
      break;

    case UserRoleType.EVALUATOR:
      PATH = PATHS_EVALUATOR;
      break;

    case UserRoleType.VENDOR:
      PATH = PATHS_VENDOR;
      break;

    default:
      break;
  }

  return PATH;
};
