export const ar = {
  volunteer: {
    not_volunteer: 'أنت لست متطوعًا',
  },
  campaign_task: {
    full_request: 'المهمة كاملة/غير متوفرة ، جميع المقاعد التي تم أخذها بالفعل',
    approved_volunteer: 'لقد أصبحت بالفعل متطوعًا في هذه المهمة',
    already_applied: 'لقد تقدمت بالفعل بطلب لهذه المهمة ، يرجى انتظار الموافقة على المسؤول',
    apply_request_not_found: 'لا يمكن العثور على طلب',
    already_rejected: 'تم رفض هذا الطلب بالفعل',
  },
};
