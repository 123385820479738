export const ar = {
  button: {
    view: 'منظر',
    delete_donor: 'حذف المتبرع',
    edit_donor: 'تعديل متبرع',
  },
  form: {
    donor_name: {
      label: 'اسم المتبرع',
      required: 'اسم المتبرع مطلوب',
    },
    email: {
      label: 'بريد إلكتروني',
    },
    date_joined: {
      label: 'تاريخ الانضمام',
    },
  },
  label: {
    DAILY: 'يوميًا',
    WEEKLY: 'أسبوعي',
    MONTHLY: 'شهريا',
    YEARLY: 'سنوي',
    CAMPAIGN: 'حملة',
    ITEM: 'عنصر المشروع',
    SILVER: 'زكاة',
    GOLD: 'زكاة',
  },
};
