export const ar = {
  button: {
    add_evaluator: 'إضافة مقيم',
    view: 'منظر',
    edit_evaluator: 'تحرير المقيم',
    delete_evaluator: 'حذف المقيم',
    approve: 'يعتمد',
    reject: 'رفض المقيم',
    upload_new_invoice: 'تحميل فاتورة جديدة',
    view_public_profile: 'عرض الملف الشخصي العام',
    back: 'خلف',
    public_page: 'الصفحة العامة للمقيم',
    profile_requests: 'طلبات الملف الشخصي',
    chat_with_evaluator: 'التحدث الى المقيم',
    more_options: 'خيارات اخرى',
    add: 'اضافة',
  },
  title: {
    all_evaluation_request: 'كل طلب التقييم',
    new_evaluation_request: 'طلب تقييم جديد',
  },
  form: {
    name: {
      label: 'اسم المقيم',
      required: 'اسم المقيم مطلوب',
    },
    profile_picture: {
      label: 'تحميل صورة الملف الشخصي',
      required: 'صورة الملف الشخصي مطلوبة.',
    },
    mosque_name: {
      label: 'اسم المسجد',
      required: 'اسم المسجد مطلوب',
    },
    location: {
      label: 'المواقع',
    },
    request_date: {
      label: 'تاريخ الطلب',
    },
  },
  mosque_form: {
    repair_cost: {
      label: 'تكلفة الصيانة',
      required: 'تكلفة الصيانة مطلوبة',
    },
    cover_image: {
      label: 'صورة الغلاف',
      required: 'صورة الغلاف مطلوبة',
    },
    walls: {
      label: 'جدران',
    },
    structural: {
      label: 'بناء',
    },
    amount: {
      label: 'كمية',
      required: 'المبلغ مطلوب',
      min_1: 'يجب أن يكون المبلغ أكبر من 0',
    },
  },
  headers: {
    date_added: 'تاريخ الاضافة',
    condition: 'الحالة',
    maintenance_progress: 'تقدم اخر عملية صيانة',
    mosque_name: 'اسم المسجد',
    mosque_needs: 'احتياجات المسجد',
    repair_cost: 'تكلفة الصيانة',
    chat_with_super_admin: 'التحدث الى المسؤول',
    chat_with_evaluator: 'التحدث الى المقيم',
  },
  tabs: {
    evaluation_requested: 'التقييمات المطلوبة',
    evaluation_finished: 'التقييمات المنتهية',
  },
  details: {
    finished_maintenance: 'الصيانة المنتهية',
    fired_maintenance: 'الصيانة المبدوءة',
    email: 'البريد الالكتروني',
  },
};
