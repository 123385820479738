export const en = {
  caption: {
    regions: 'Regions',
    region_details: 'Region Details',
    governorates: 'Governorates',
    governorate_details: 'Governorates Details',
    select_region: 'Select Region',
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    edit_governorate: 'Edit Governorate',
    add_new_governorate: 'Add Governorate',
    add_new_region: 'Add Region',
    edit_region: 'Edit Region',
  },
  form: {
    region_name: {
      label: 'Region Name',
      required: 'Region Name is required!',
    },
    governorate_name: {
      label: 'Governorate Name',
      required: 'Governorate Name is required!',
    },
    latitude: {
      label: 'Latitude',
      placeholder: 'e.g: 23.885942',
      required: 'Latitude is required!',
      max_float: 'The amount should be a decimal with maximum seven digits after the comma',
      min: 'Latitude must be at least -90',
      max: 'Latitude must be at least 90',
      type_error: 'Latitude must be a valid number',
    },
    longitude: {
      label: 'Longitude',
      placeholder: 'e.g: 45.0791621',
      required: 'Longitude is required!',
      max_float: 'The amount should be a decimal with maximum seven digits after the comma',
      min: 'Longitude must be at least -180',
      max: 'Longitude must be at least 180',
      type_error: 'Longitude must be a valid number',
    },
  },
};
