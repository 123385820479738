export const en = {
  form: {
    full_name: {
      label: 'Enter you fullname',
      required: 'Your Fullname is required!',
    },
    bio: {
      label: 'Bio',
      required: 'Your Bio is required!',
    },
    address: {
      label: 'Your Location',
      required: 'Your Location is required!',
    },
    profile_picture_url: {
      label: 'Profile picture',
      required: 'Profile picture is required!',
    },
  },
  button: {
    sort_by: 'Sort by',
    delete_picture: 'Delete Picture',
    save_setting: 'Save Setting',
  },
};
