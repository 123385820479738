export const ar = {
  volunteer: {
    success_applied: 'تطبق المتطوع على المهمة بنجاح',
    success_applied_responded: 'تطبق المتطوع استجاب بنجاح',
  },
  campaign_edit: {
    ask_changes: 'تم إرسال طلب التغييرات الخاص بك بنجاح',
    respond_changes: 'تم تحرير الحملة بنجاح',
  },
  governorates: {
    success_delete: 'تم حذف المحافظة',
    success_create: 'تم إنشاء محافظة جديدة',
    success_update: 'تم تحديث المحافظة',
  },
  regions: {
    success_create: 'تم إنشاء منطقة جديدة',
    success_update: 'تم تحديث المنطقة',
  },
};
