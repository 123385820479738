import { Configuration } from '@otarid/ommar-sdk';

import { HOST_API } from 'src/config-global';

export const workerApisConfiguration = new Configuration({
  basePath: HOST_API,
  baseOptions: {
    credentials: 'include',
  },
});
