export const ar = {
  title: {
    project_marketplace: 'سوق المشروع',
    status: 'حالة',
  },
  button: {
    add_new_item: 'أضف أداة جديدة',
    submit: 'يُقدِّم',
  },
  form: {
    name: {
      label: 'اسم العناصر',
      required: 'اسم العنصر مطلوب',
    },
    amount_needed: {
      label: 'إجمالي مطلوب',
      min_1: 'يجب أن يكون أكبر من 1',
      required: 'مطلوب إجمالي المطلوب',
    },
    provide_items: {
      label: 'توفير العناصر',
      required: 'توفير العنصر مطلوب',
    },
    price: {
      label: 'سعر',
      min_1: 'يجب أن يكون أكبر من 1',
      required: 'السعر مطلوب',
    },
  },
};
