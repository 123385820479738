export const en = {
  campaign_task: {
    not_found: 'Task not found!',
  },
  campaign: {
    not_found: 'Campaign not found!',
  },
  evaluator: {
    not_found: 'Evaluator Not Found',
  },
};
