import { useContext } from 'react';

import { SocketContext } from '../context/ticket/socket-context';

// ----------------------------------------------------------------------

export const useSocketTicketContext = () => {
  const context = useContext(SocketContext);

  if (!context) throw new Error('useSocketTicketContext context must be use inside AuthProvider');

  return context;
};
