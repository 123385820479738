import { en as conflict } from 'src/locales/exception/409/en';
import { en as not_found } from 'src/locales/exception/404/en';
import { en as bad_request } from 'src/locales/exception/400/en';
import { en as forbidden_exception } from 'src/locales/exception/403/en';

export const en = {
  409: conflict,
  404: not_found,
  400: bad_request,
  403: forbidden_exception,
};
