export const ar = {
  campaign_task: {
    not_found: 'المهمة لم يتم العثور عليها',
  },
  campaign: {
    not_found: 'لم يتم العثور على الحملة',
  },
  evaluator: {
    not_found: 'لم يتم العثور على المُقيِّم',
  },
};
