/* eslint-disable no-nested-ternary */

import { useEffect, useCallback } from 'react';

import { useRouter, useSearchParams } from 'src/routes/hooks';

import { SplashScreen } from 'src/components/loading-screen';

import { UserRoleType } from '../types';
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: Props) {
  const { loading } = useAuthContext();

  return <>{loading ? <SplashScreen /> : <Container>{children}</Container>}</>;
}

// ----------------------------------------------------------------------

function Container({ children }: Props) {
  const router = useRouter();

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');

  const { authenticated, user } = useAuthContext();

  const check = useCallback(() => {
    if (authenticated && user) {
      const { activeRole } = user;

      let role: string = activeRole.toLowerCase();

      if (role === UserRoleType.CLUSTER_ADMIN) {
        role = 'admin';
      }

      router.replace(
        activeRole ? `/${role}/dashboard` : returnTo ?? '/'
        // activeRole
        //   ? role === UserRoleType.OPERATOR
        //     ? `/${role}/project/all`
        //     : `/${role}/dashboard`
        //   : returnTo ?? '/'
      );
    }
  }, [authenticated, user, returnTo, router]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}
