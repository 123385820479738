export const en = {
  title: {
    project_marketplace: 'Project Marketplace',
    status: 'Status',
  },
  button: {
    add_new_item: 'Add New Item',
    submit: 'Submit',
  },
  form: {
    name: {
      label: "Items's Name",
      required: "Item's name is required!",
    },
    amount_needed: {
      label: 'Total Needed',
      min_1: 'Must be greater than 1',
      required: 'Total needed is required!',
    },
    provide_items: {
      label: 'Provide Items',
      required: 'Provide item is required!',
    },
    price: {
      label: 'Price',
      min_1: 'Must be greater than 1',
      required: 'Price is required!',
    },
  },
};
