export const ar = {
  caption: {
    add_new_ticket: 'أضف تذكرة جديدة',
    new_discussion: 'مناقشة جديدة',
    latest_tickets: 'أحدث التذاكر',
    loading: 'تحميل',
    empty: 'فارغ',
    notifications: 'إشعارات',
  },
  button: {
    new_ticket: 'تذكرة جديدة',
    send_ticket: 'إرسال التذكرة',
    send_message: 'يرسل',
    reload_data: 'بيانات إعادة تحميل',
    confirm: 'يتأكد',
    update_status: 'حالة التحديث',
    cancel: 'يلغي',
  },
  form: {
    discussion_for: {
      label: 'مناقشة',
    },
    selected_id: {
      label: 'حدد العنصر',
    },
    name: {
      label: 'اسم',
    },
    department: {
      label: 'قسم',
    },
    last_update: {
      label: 'اخر تحديث',
    },
    condition: {
      label: 'حالة',
    },
    name_of_ticket: {
      label: 'اسم التذكرة',
      required: 'مطلوب اسم التذكرة',
    },
    message_of_ticket: {
      label: 'رسالة',
      required: 'يجب عليك إدخال الموضوع الذي يجب مناقشته',
    },
    selected_donor: {
      label: 'حدد المانح',
    },
    message: {
      label: 'أدخل رسالة',
      required: 'يجب عليك إدخال رسالتك',
    },
    ticket_status: {
      label: 'حالة التذكرة',
      required: 'حالة التذكرة مطلوبة',
    },
    forwarded_to: {
      label: 'أرسل إلى ...',
      required: 'يجب عليك تحديد المستخدم',
    },
  },
};
