export const ar = {
  operator: {
    cant_delete_project_in_progress: `لدى المشغل مشاريع قيد التقدم`,
    cant_delete_campaign_in_progress: `لدى المشغل حملات قيد التقدم`,
  },
  donor: {
    email_exist: 'الايميل موجود مسبقا',
    regions: {
      latitude_exist: 'سلك خط العرض يستخدم لمكان آخر',
      longitude_exist: 'سلك الطول يستخدم لمكان آخر',
    },
  },
};
