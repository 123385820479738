export const ar = {
  button: {
    add_vendor: 'إضافة البائع',
    view: 'منظر',
    edit_vendor: 'تحرير البائع',
    delete_vendor: 'حذف البائع',
    approve: 'يعتمد',
    reject: 'رفض البائع',
    upload_new_invoice: 'تحميل فاتورة جديدة',
    view_public_profile: 'عرض الملف الشخصي العام',
  },
  form: {
    name: {
      label: 'اسم البائع',
      required: 'اسم البائع مطلوب',
    },
    field: {
      label: 'مجال الشركة',
      required: 'مجال الشركة مطلوب',
    },
    type_project_handled: {
      label: 'نوع مقبض المشروع',
      required: 'نوع مقبض المشروع مطلوب',
    },
    logo: {
      label: 'تحميل شعار الشركة',
      required: 'شعار الشركة مطلوب.',
    },
    similiar_project: {
      label: 'تحميل مشروع مشابه',
      required: 'مشروع مماثل مطلوب.',
    },
    analities: {
      label: 'قم بتحميل أي ملفات تساعدنا على فهم تصنيفاتك بشكل أفضل',
      required: 'ملف التصويري مطلوب',
    },
    date_added: {
      label: 'تم إضافة التاريخ',
    },
    email: {
      label: 'بريد إلكتروني',
    },
    finished_campaigns: {
      label: 'الحملات الانتهاء',
    },
    fired_campaigns: {
      label: 'الحملات المطلقة',
    },
    income_status: {
      label: 'حالة الدخل',
    },
    new_vendor_income: {
      label: 'أدخل الميزانية',
      min_1: 'الميزانية يجب أن تكون أكبر من 1',
      required: 'الميزانية مطلوبة',
      max_only_money: 'يجب أن تكون ميزانيتك متساوية أو أقل من',
      previous_budget: 'الميزانية السابقة',
    },
    notes: {
      label: 'ملحوظات',
      required: 'يجب عليك ملء الملاحظات',
    },
  },
  caption: {
    add_new_edit_request_campaign: 'أضف حملة طلب تحرير جديد',
    old_values: 'القيم القديمة',
    new_values: 'قيم جديدة',
  },
};
