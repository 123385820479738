export const en = {
  operator: {
    cant_delete_project_in_progress: `Operator has existing project progress`,
    cant_delete_campaign_in_progress: `Operator has existing campaign progress`,
  },
  donor: {
    email_exist: 'Email already exists',
    regions: {
      latitude_exist: 'Latitude cordinate is using for another place!',
      longitude_exist: 'Longitude cordinate is using for another place!',
    },
  },
};
