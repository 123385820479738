export const ar = {
  title: {
    blog: 'مدونة',
    all_blogs: 'كل المدونات',
    new_blog: 'مدونة جديدة',
    create: 'انشاء مدونة',
    edit: 'تعديل مدونة',
    something_awesome: 'اكتب شيئا مثيرا للاهتمام',
    drop_files: 'قم بإسقاط الملفات هنا أو انقر للتحميل',
  },
  headers: {
    title: 'العنوان',
    date_of_write: 'تاريخ الانشاء',
    view: 'المشاهدات',
    category: 'الفئة',
  },
  button: {
    view: 'عرض',
    edit_blog: 'تعديل المدونة',
    delete_blog: 'حذف مدونة',
    add_blog: 'مدونة جديدة',
  },
  form: {
    content: {
      label: 'المحتوى',
      required: 'المحتوى مطلوب',
    },
    name: {
      label: 'الاسم',
      required: 'الاسم مطلوب',
    },
    description: {
      label: 'الوصف',
      required: 'الوصف مطلوب',
    },
    coverUrl: {
      label: 'صورة الغلاف',
      required: 'رابط صورة الغلاف مطلوب',
    },
    category: {
      label: 'الفئة',
      required: 'الفئة مطلوبة',
    },
    project: {
      label: 'المشروع',
      required: 'المشروع مطلوب',
    },
  },
};
