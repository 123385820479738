export const en = {
  caption: {
    all_request: 'All Maintenance Request',
    detail_request: 'Details Maintenance Request',
    card_detail: {
      location: 'Location',
      description: 'Description',
      overal_rates: 'Overal Rates',
    },
    attachments: 'Attachments',
    photos: 'Photos',
    project_approved: 'Project Approved',
    maximum_evaluator: 'Please enter the maximum evaluator for the Project',
    evaluator_assigned: 'Evaluator Assigned',
    project_status: 'Project Status',
    all_evaluation_request: 'All Evaluation Request',
    detail_evaluation_request: 'Detail Evaluation Request',
    evaluation: 'Evaluation',
    project_evaluated: 'Project Evaluated',
    my_evaluation_task: 'My Evaluation Task',
    step: 'Step',
    all_mosques_evaluated: 'All Mosques Evaluated',
    detail_mosques_evaluated: 'Details Mosque Evaluated',
  },
  form: {
    evaluator_for_project: {
      label: 'Evaluator for the Project',
      placeholder: 'e.g 10 Evaluator',
      required: 'You must enter maximum Evaluator',
      min: 'Please enter the Evaluator greater than 0',
    },
    mosque_name: {
      label: 'Mosque Name',
    },
    locations: {
      label: 'Locations',
    },
    request_date: {
      label: 'Request Date',
    },
    evaluate_date: {
      label: 'Evaluate Date',
    },
    selection: {
      label: 'Select your option',
      required: 'You must select one of options!',
      options: {
        very_poor: 'Very Poor (1-2)',
        poor: 'Poor (3-4)',
        fair: 'Fair (5-6)',
        good: 'Good (7-8)',
        excellent: 'Excellent (9-10)',
        necessary: 'Necessary',
        unnecessary: 'Unnecessary',
      },
    },
    image: {
      label: 'Upload Photo',
      required: 'You must add minimun one photo!',
    },
    prayer_hall: {
      label: 'Prayer Hall',
    },
    wet_areas: {
      label: 'Wet Areas',
    },
    minaret: {
      label: 'Minaret',
    },
    exterior_walls: {
      label: 'Exterior Walls',
    },
    foundation: {
      label: 'Foundation',
    },
    roof_inspection_and_repair: {
      label: 'Roof Inspections and Repair',
    },
    floor: {
      label: 'Floor',
    },
    walls_and_ceilings: {
      label: 'Walls and Ceilings',
    },
    windows_and_doors: {
      label: 'Windows and Doors',
    },
    electrical_system: {
      label: 'Electrical System',
    },
    fire_safety: {
      label: 'Fire Safety',
    },
    lighting: {
      label: 'Lighting',
    },
    finishes_and_decorations: {
      label: 'Finishes and Decorations',
    },
    temperature: {
      label: 'Temperature',
    },
    architectural: {
      label: 'Architectural',
    },
    operational: {
      label: 'Operational',
    },
    humidity: {
      label: 'Humidity',
    },
    special_water_conditions: {
      label: 'Special Water Conditions',
    },
    factor_type: {
      label: 'Factor',
    },
    images: {
      label: 'Images',
    },
    expected_cost: {
      label: 'What is the expected cost?',
      placeholder: 'e.g 100',
      min_1: 'Must be greater than 1',
      required: 'Expected cost is required',
    },
  },
  button: {
    approve_request: 'Approve Request',
    reject_request: 'Reject Request',
    apply_for_evaluation: 'Apply for Evaluation',
    archive_evaluation: 'Archive this Evaluation',
    add_photos: 'Add Photos',
    accept_request: 'Accept Request',
    approve_evaluation: 'Approve Evaluation',
    reject_evaluation: 'Reject Evaluation',
    save_changes: 'Save Changes',
  },
};
