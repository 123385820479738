import { useMemo } from 'react';
import { m } from 'framer-motion';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { useRouter } from 'src/routes/hooks';
import { ROOTS_TYPE, PATHS_DASHBOARD } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import { UserRoleType } from 'src/auth/types';
import { ASSETS_API } from 'src/config-global';
import { useAuthContext } from 'src/auth/hooks';
import { useSocketTicketContext } from 'src/socket-ticket/hooks';

import { varHover } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();

  const { user, authenticated, logout } = useAuthContext();

  const { handleDisconnectSocket } = useSocketTicketContext();

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate();

  const popover = usePopover();

  const OPTIONS = useMemo(() => {
    let linkItems: {
      value: string;
      label: string;
      linkTo: string;
    }[] = [];

    if (authenticated && user) {
      const { activeRole } = user;

      let role: string = activeRole.toLowerCase();

      if (role === UserRoleType.CLUSTER_ADMIN) {
        role = 'admin';
      }

      const roleType: ROOTS_TYPE = role.toUpperCase() as ROOTS_TYPE;

      linkItems = [
        {
          label: t('menu.dashboard'),
          value: 'user.dashboard',
          linkTo: '/',
        },
        {
          label: t('menu.settings.title'),
          value: 'menu.settings',
          linkTo: PATHS_DASHBOARD(roleType).settings.root,
        },
      ];
    }

    return linkItems;
  }, [user, authenticated, t]);

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    } finally {
      handleDisconnectSocket();
      popover.onClose();
      router.replace('/');
    }
  };

  const handleClickItem = (path: string) => {
    popover.onClose();
    router.push(path);
  };

  const userFullname = useMemo(() => {
    let actualName: string = user?.full_name || '';

    if (user && user.vendor_details) {
      actualName = user.vendor_details.name;
    }

    if (user && user.operator_details) {
      actualName = user.operator_details.name;
    }

    if (user && user.evaluator) {
      actualName = user.evaluator.name;
    }

    return actualName;
  }, [user]);

  const photoUrl = useMemo(() => {
    let photo: string | undefined = user?.profile_picture_url
      ? `${ASSETS_API}/${user.profile_picture_url}`
      : undefined;

    if (user && user.vendor_details) {
      photo = user.vendor_details.profile_picture_url
        ? `${ASSETS_API}/${user.vendor_details.profile_picture_url}`
        : undefined;
    }

    if (user && user.operator_details) {
      photo = user.operator_details.profile_picture_url
        ? `${ASSETS_API}/${user.operator_details.profile_picture_url}`
        : undefined;
    }

    if (user && user.evaluator) {
      photo = user.evaluator.profile_picture_url
        ? `${ASSETS_API}/${user.evaluator.profile_picture_url}`
        : undefined;
    }

    return photo;
  }, [user]);

  return (
    <>
      <Button
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        variant="text"
        onClick={popover.onOpen}
        sx={{
          justifyContent: 'center',
          gap: 1.5,
        }}
      >
        <Avatar
          src={photoUrl}
          alt={user?.email}
          sx={{
            width: 33,
            height: 33,
            border: (theme) => `solid 2px ${theme.palette.info.main}`,
            bgcolor: (theme) => theme.palette.grey[200],
            '& .MuiSvgIcon-root': {
              fill: (theme) => theme.palette.text.disabled,
            },
          }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.15, alignItems: 'flex-start' }}>
          <Typography
            variant="subtitle2"
            sx={{
              color: (theme) => theme.palette.info.main,
              fontWeight: (theme) => theme.typography.fontWeightBold,
            }}
          >
            {user && user.activeRole ? t(`roles.${user?.activeRole}`) : '-'}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: (theme) => theme.palette.text.disabled,
            }}
          >
            {userFullname}
          </Typography>
        </Box>
      </Button>

      <CustomPopover
        open={popover.open}
        hiddenArrow
        onClose={popover.onClose}
        sx={{ width: 200, p: 0 }}
      >
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          {t('menu.logout')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
