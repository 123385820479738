export function paramCase(str: string) {
  return str
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/g, '');
}

export function snakeCase(str: string) {
  return str
    .toLowerCase()
    .replace(/\s+/g, '_')
    .replace(/[^a-z0-9_]/g, '');
}

export const capitalCase = (text: string) => {
  const words = text.toLowerCase().split(/[_+-\s]/);
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

  const finalFormattedWords = capitalizedWords.map((word) => {
    const index = word.indexOf('(');
    if (index !== -1 && word.length > index + 1) {
      const charAfterOpeningBracket = word.charAt(index + 1).toUpperCase();
      return word.slice(0, index + 1) + charAfterOpeningBracket + word.slice(index + 2);
    }
    return word;
  });

  return finalFormattedWords.join(' ');
};
